<template>
  <div>
    <juego ref="board" v-show="playing" @restart="restart" />
    <opcion ref="optionsPane" @gameStart="start" v-show="!playing" />
  </div>
</template>

<script>
import juego from './Juego'
import opcion from './Opcion'

export default {
  name: 'app',
  components: {
    juego,
    opcion
  },

  data () {
    return {
      playing: false
    }
  },

  methods: {
    start (...args) {
      this.playing = true
      this.$refs.board.start(...args)
    },

    restart () {
      this.playing = false
      this.$refs.optionsPane.reset()
    }
  }
}
</script>