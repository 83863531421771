<template>
<div>
   <br v-if="isMobile">
   <b-row class="justify-content-center">
    <b-col cols="12" md="4" v-if="isMobile">     
        <div class="text-center mt-4" id="countdown2" >
            <h2 :style="isMobile ? 'cursor:pointer; font-size:30px; font-family:Saira Stencil One, cursive; color:#5ab71a;' : 'cursor:pointer; font-size:80px; font-family:Saira Stencil One, cursive; color:#5ab71a;'"  id="countdown2">
                <img src="elementos/reloj.png" class="rounded" alt="Image" :style="!isMobile ? 'width:15%;' : 'width:8%; margin-top:-8px;'"> {{ countdown }}
            </h2>
        </div>
        <br>
    </b-col>
        <b-col cols="11" md="4">
            <!-- <p class="text-white text-center" style="font-size:30px;">Entrenamiento</p> -->
           
            <div class="text-center" id="countdown2" v-if="!isMobile">
                <h2 style="cursor:pointer; font-size:40px; font-family:Saira Stencil One, cursive; color:#5ab71a;"  id="countdown2">
                    <img src="elementos/reloj.png" class="rounded" alt="Image" :style="!isMobile ? 'width:8%; margin-top:-15px;' : ''"> {{ countdown }}
                </h2>
            </div>
            <br>
            <template v-if="juego == 1">
              <p  class="text-center" style="color:#fff; font-size:20px;">Arma el rompecabezas y ¡suma puntos!</p>  
              <br>
                <form id="optionsForm" @submit.prevent="start" class="mt-3">
                   <center><img :src="image" v-if="image" class="rounded float-center" :width="!isMobile ? '450' : ''"></center> 
                           <!-- <label for="file">
                        Select an image
                        <input type="file"
                            id="file"
                            accept="image/gif, image/jpeg, image/png"
                            @change="fileChanged">
                        </label> -->
                    <div class="text-center">
                        <v-btn class="mt-5"  type="submit" v-if="image"  color="#5cb617" style="text-transform:capitalize;">Comenzar</v-btn>
                    </div>
                </form>
            </template>
            <template v-else-if="juego == 0">
                 <b-card  style="background-color:rgba(0, 0, 0, 0); border-color:#187abe;">
                    <br v-if="!isMobile">
                      <b-card-text class="text-center">
                        <p class="text-white" :style="isMobile ? 'font-size:28px;' : 'font-size:35px;'">
                            <b>¡Felicidades!</b> <br>
                            Cumpliste la misión.
                              <br> ¡Ganaste <span style="color:#187abe;">05 Puntos!</span>
                        </p>
                      </b-card-text>
                      <br v-if="!isMobile">
                    </b-card>
            </template>
            <template v-else>
                <center><b-spinner style="color:#fff;" small></b-spinner></center> 
            </template>
        </b-col>
   </b-row>
  </div>
</template>

<script>
import loadImage from 'blueimp-load-image'
import * as moment from 'moment';
export default {
  data () {
    return {
      isMobile: this.$mobile(),
      image: this.$mobile() ? "elementos/rmovil.jpg" : "elementos/rompecabezas2.jpg",
      size: {
        horizontal: 3,
        vertical: 2
      },
      countdown: moment.utc(61).format('HH:mm:ss'),
      juego:1
    }
  }, 
  computed: {
    user() {
      return this.$store.getters.getInfoUser;
    },
  },
  methods: {
    getJuego(){
      var url= 'juego/estatus?email='+ this.user.email;
        this.$api.get(url).then(
            ({data}) => {
                console.log(data);
                this.juego = data.juego;
                if(data.juego == 0){
                  this.$confetti.start({
                      particles: [
                          {
                          type: 'rect',
                          },
                          {
                          type: 'circle',
                          },
                      ],
                      defaultColors: [
                          '#5cb617',
                          '#ffffff',
                          '#187abe'
                      ],
                  });
                  setTimeout(() => {
                      this.$confetti.stop();
                  }, 2000);
                }
            }
        );
    },
    fileChanged (e) {
      if (!e.target.files.length) {
        this.image = null
        return
      }

      loadImage(e.target.files[0], canvas => {
        this.image = canvas.toDataURL()
      }, {
        maxWidth: 600,
        maxHeight: 600,
        minWidth: 200,
        minHeight: 200,
        canvas: true
      })
    },
        /**
     * Start the game by emitting the event.
     */
    start () {
      this.$emit('gameStart', {
        image: this.image,
        size: this.size
      })
    },
    /**
     * Reset the options.
     */
    reset () {
      this.image = null
      document.querySelector('#optionsForm').reset()
      this.juego = 2;
      this.getJuego();
    } 
  }
}
</script>

<style lang="scss" scoped>


label[for=file] {
  color: #368ba0;
  cursor: pointer;
  display: inline-block;
  margin-right: 12px;
}

input[type=number] {
  height: 24px;
  font-size: 14px;
  border: 1px solid #ccc;
}

input[type=file] {
  display: none;
}

button {
  -webkit-appearance: none;
  padding: 6px 12px;
  background: #1ca76a;
  color: #fff;
  border-radius: 3px;
  border: 0;
  font-size: 14px;
  cursor: pointer;
  margin-top: 10px;
}
</style>