<template>
<div>
   <br v-if="isMobile">
   <b-row class="justify-content-center">
       <b-col cols="12" md="4" v-if="isMobile">
         
          <div class="text-center mt-4" id="countdown2" >
              <h2 :style="isMobile ? 'cursor:pointer; font-size:30px; font-family:Saira Stencil One, cursive; color:#5ab71a;' : 'cursor:pointer; font-size:80px; font-family:Saira Stencil One, cursive; color:#5ab71a;'"  id="countdown2">
                  <img src="elementos/reloj.png" class="rounded" alt="Image" :style="!isMobile ? 'width:15%;' : 'width:8%; margin-top:-8px;'"> {{ countdown }}
              </h2>
          </div> 
          <br>
      </b-col>
      <b-col cols="11" md="6">
        <!-- <p class="text-white text-center" style="font-size:30px;">Entrenamiento</p> -->
       
        <div class="text-center" id="countdown2" v-if="!isMobile" style="color:#5ab71a;">
            <h2 style="cursor:pointer; font-size:40px; font-family:Saira Stencil One, cursive; color:#5ab71a;"  id="countdown2">
                <img src="elementos/reloj.png" class="rounded" alt="Image" :style="!isMobile ? 'width:8%; margin-top:-15px; ' : ''"> {{ countdown }}
            </h2>
        </div>
        <p v-if="!valid" class="text-center" style="color:#fff; font-size:20px;">Arma el rompecabezas y ¡suma puntos!</p>  
        <div class="board mt-4">
          <div class="frame-wrapper" :style="frameSize">
            <template v-if="!valid">
                <div class="original"
                    v-if="showingOriginal && image"
                    @click="showingOriginal = false"
                    :style="{ background: `url(${image})`}">
                </div>
                <div class="frame" :style="frameSize">
                  <Tile v-for="tile in tiles"
                    :key="tile.position"
                    :tile="tile"
                    @moving="moveTile"
                    ref="tiles"
                  />
                </div>
            </template>
            <template v-else>
                <p v-if="aviso" class="win text-center" :style="isMobile ? 'font-size:20px;' : 'font-size:35px;'">
                  <br>
                  <b>¡Felicidades!</b>
                  <br>
                  Ganaste 5 puntos
                </p>
              <center><img :src="image" class="rounded float-center" :width="!isMobile ? '600' : ''"></center> 
            </template>
          </div>
        </div> 
      </b-col>
      <!-- <b-col cols="11" md="4" v-if="!isMobile" class="mt-5">     
            <p  class="text-left" style="color:#fff; font-size:25px; margin-top:35px;">Arma el rompecabezas y <br> ¡suma puntos!</p>       
            <div class="text-left mt-4" id="countdown2">                   
                <h2 style="cursor:pointer; font-size:60px; margin-top:40px; font-family:Saira Stencil One, cursive;"  id="countdown2">
                    <img src="elementos/reloj.png" class="rounded" alt="Image" :style="!isMobile ? 'width:8%; margin-top:-15px;' : ''"> {{ countdown }}
                </h2>
            </div>
           <center>
              <v-btn class="mt-5 text-left "  color="#5cb617" v-if="valid"   :style="isMobile ? 'width:80%; text-transform:capitalize;':'width:25%; margin-right:450px; text-transform:capitalize;'" @click="respondeJuego()">Finalizar <b-spinner v-if="loader" small></b-spinner> </v-btn>
            </center>
        </b-col> -->
   </b-row>
</div>
</template>

<script>
import sample from 'lodash.sample'
import Tile from './Tile'
import * as moment from 'moment';
let backupTiles = null

export default {
  components: { Tile },

  data () {
    return {
      loader:false,
      image: null,
      showingOriginal: false,
      size: {
        horizontal: 0,
        vertical: 0
      },
      tiles: [],
      tileSize: {
        width: 0,
        height: 0
      },
         isMobile: this.$mobile(),
        countdown: moment.utc(61).format('HH:mm:ss'),
        expires_in: null,
        interval: null,
        Clickinterval: false,
        aviso:false
    }
  },

  computed: {
    user() {
      return this.$store.getters.getInfoUser;
    },
    frameSize () {
      return {
        width: `${this.tileSize.width * this.size.horizontal}px`,
        height: `${this.tileSize.height * this.size.vertical}px`
      }
    },

    /**
     * The total number of tiles in the current board.
     * @return {Number}
     */
    totalTiles () {
      return this.size.horizontal * this.size.vertical
    },

    /**
     * Determine if the current board is valid (solved).
     * @return {boolean}
     */
    valid () {
      if (!this.tiles.length) {
        return false
      }

      for (let i = 0; i < this.totalTiles; ++i) {
        if (this.tiles[i].styles.order !== this.tiles[i].position) {
          return false
        }
      }
       this.getAviso();

      // setTimeout(() => {
        return true
      //  }, 3000)

       
    }
  },
  methods: {
    getAviso(){
      setTimeout(() => {
        this.aviso = true;
       }, 3000)
    },
     respondeJuego(){     
       this.$api.post('juego/store', {
            mrt: this.user.mrt,
            idpdv: this.user.id_pdv,
            email: this.user.email,
            seccion: 'Juego',
            puntos: 5,
            perfil: this.user.perfil,
            respuesta: 'Rompecabezas',
            tiempo: this.countdown
        }).then(response => {
                console.log(response);
                this.loader = false;  
                clearInterval(this.interval);
                this.restart();
            })
            .catch(error => {
                console.log(error);
                this.loader = false;
            });
    },
    start ({ image, size }) {
      this.size = size
      this.image = image
      // detect the width and height of the frame
      const img = new Image()
      img.onload = () => {
        this.tileSize.width = Math.floor(img.width / size.horizontal)
        this.tileSize.height = Math.floor(img.height / size.vertical)
        this.generateTiles()
        this.shuffleTiles()
      }
      img.src = image

      this.countdown = moment.utc(32).format('HH:mm:ss');
      this.expires_in = 1;
      this._setInterval();
    },
    _setInterval: function() {
        if(this.Clickinterval==false){
            this.interval = setInterval(() => {
                if (this.expires_in === 0) {
                    clearInterval(this.interval);
                } else {
                    this.expires_in += 1;
                    if(this.expires_in>0){
                        this.countdown = moment.utc(this.expires_in * 1000).subtract(1, 'seconds').format('HH:mm:ss');
                    }
                }
            }, 1000);
            this.Clickinterval = true;
        }
    },
    /**
     * Generate the tiles for the current game.
     */
    generateTiles () {
      this.tiles = []
      for (let i = 0; i < this.totalTiles; ++i) {
        this.tiles.push({
          styles: {
            background: i === 0 ? 'transparent' : `url(${this.image})`,
            backgroundPositionX: `-${(i % this.size.horizontal) * this.tileSize.width}px`,
            backgroundPositionY: `-${Math.floor(i / this.size.horizontal) * this.tileSize.height}px`,
            width: `${this.tileSize.width}px`,
            height: `${this.tileSize.height}px`,
            order: i
          },
          position: i,
          isEmpty: i === 0
        })
      }
    },

    /**
     * Shuffle the generated tiles.
     */
    shuffleTiles () {
      // To make sure the puzzle is solvable, we execute a series of random moves
      for (let i = 0, j = this.totalTiles * 3; i < j; ++i) {
        const emptyTile = this.tiles.find(t => t.isEmpty)
        const movableTiles = this.tiles.filter(t => {
          return this.getAdjacentOrders(emptyTile).indexOf(t.styles.order) > -1
        })
        this.switchTiles(emptyTile, sample(movableTiles))
      }

      // Make a backup for later reset
      backupTiles = JSON.stringify(this.tiles)
    },

    /**
     * Move a (movable) tile
     * @param  {Object} tile
     */
    moveTile (tile) {
      if (tile.isEmpty) {
        return
      }

      // Find the 4 direct (non-diagonal) adjacent tiles and see if any of them is the empty tile
      const target = this.tiles.find(t => {
        return t.isEmpty && this.getAdjacentOrders(tile).indexOf(t.styles.order) > -1
      })

      // If found the empty tile, just switch the flex order and we're good.
      target && this.switchTiles(target, tile)
    },

    /**
     * Switch two tiles.
     * @param  {Object} a First tile
     * @param  {Object} b Second tile
     */
    switchTiles (a, b) {
      [a.styles.order, b.styles.order] = [b.styles.order, a.styles.order]
    },

    /**
     * Get the four direct (non-diagonal) adjacent tiles' orders of a tile.
     * @param  {Object} tile
     * @return {Array.<Number>}
     */
    getAdjacentOrders (tile) {
      const pos = tile.styles.order
      return [
        pos % this.size.horizontal ? pos - 1 : null,
        (pos + 1) % this.size.horizontal ? pos + 1 : null,
        pos - this.size.horizontal,
        pos + this.size.horizontal
      ]
    },

    /**
     * Reset the board.
     */
    reset () {
      this.tiles = JSON.parse(backupTiles)
    },

    /**
     * Restart the game.
     */
    restart () {
      this.$emit('restart')
    }
  }
}
</script>

<style lang="scss">
.frame-wrapper {
  margin: 0 auto;
  position: relative;
  box-shadow: 0 0 0px 10px;

  .original {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  p.win {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, .5);
    color: #fff;
    // font-size: 20px;
    margin: 0 0;
    background: rgba(10, 17, 12, 0.7);
  }
}

.frame {
  display: flex;
  flex-wrap: wrap;
  background: #5cb617;
  background-size: cover;
}

.controls {
  margin-top: 30px;

  a {
    display: inline-block;
    text-decoration: none;
    padding: 6px 12px;
    background: #f78403;
    color: #fff;
    border-radius: 3px;

    &.toggle-original {
      background: #d05b88;
    }

    &.restart {
      background: #368ba0;
    }

    &.shuffle {
      background: #3ebb5c;
    }
  }
}
</style>